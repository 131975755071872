import React from "react";
import {
  Activity,
  Bell,
  CheckSquare,
  Database,
  FileText,
  Gift,
  Layers,
  List,
  MapPin,
  MessageSquare,
  Monitor,
  Phone,
  Settings,
  ThumbsUp,
  User,
  UserMinus,
  Users,
  Aperture,
  Share2,
} from "react-feather";

// icon image
import contentIcon from "../assets/img/svg/content.svg";
import partnerIcon from "../assets/img/svg/partner.svg";
import PlanIcon from "../assets/img/svg/plan.svg";

export const SideBarNavigation = [
  {
    id: 0,
    path: "/user",
    icon: <User style={{ color: "#e2ac07" }} size={18} />,
    title: "User",
  },
  {
    id: 1,
    path: "/employees",
    icon: <Users style={{ color: "#e2ac07" }} size={18} />,
    title: "Employees",
  },
  {
    id: 2,
    path: "/roles",
    icon: (
      <img
        src={PlanIcon}
        alt=""
        color="#e2ac07"
        style={{ width: "16px", height: "16px", objectFit: "cover" }}
      />
    ),
    title: "Roles",
  },
  {
    id: 3,
    path: "/partners",
    icon: (
      <img
        src={partnerIcon}
        alt=""
        color="#e2ac07"
        style={{ width: "16px", height: "16px", objectFit: "cover" }}
      />
    ),
    title: "Partners",
  },
  {
    id: 4,
    path: "/plans",
    icon: (
      <img
        src={PlanIcon}
        alt=""
        color="#e2ac07"
        style={{ width: "16px", height: "16px", objectFit: "cover" }}
      />
    ),
    title: "Plans",
  },
  {
    id: 5,
    path: "/content",
    icon: (
      <img
        src={contentIcon}
        alt=""
        style={{ width: "16px", height: "16px", objectFit: "cover" }}
      />
    ),
    title: "Content",
  },
  {
    id: 6,
    path: "/category",
    icon: <List style={{ color: "#e2ac07" }} size={18} />,
    title: "Category",
  },
  {
    id: 7,
    path: "/requests",
    icon: <UserMinus style={{ color: "#e2ac07" }} size={18} />,
    title: "Requests",
  },
  {
    id: 8,
    path: "/portfolios",
    icon: <Database style={{ color: "#e2ac07" }} size={18} />,
    title: "Portfolio",
  },
  {
    id: 21,
    path: '/share',
    icon: <Share2 style={{ color: "#e2ac07" }} size={18} />,
    title: "Invite Friends",
  },
  {
    id: 9,
    path: "/task",
    icon: <CheckSquare style={{ color: "#e2ac07" }} size={18} />,
    title: "Task",
  },
  {
    id: 10,
    path: "/invoice",
    icon: <FileText style={{ color: "#e2ac07" }} size={18} />,
    title: "Invoice",
  },
  {
    id: 11,
    path: "/markerImage",
    icon: <MapPin style={{ color: "#e2ac07" }} size={18} />,
    title: "Marker Image",
  },
  {
    id: 12,
    path: "/transaction",
    icon: <Layers style={{ color: "#e2ac07" }} size={18} />,
    title: "Transaction",
  },
  {
    id: 13,
    path: "/notification",
    icon: <Bell style={{ color: "#e2ac07" }} size={18} />,
    title: "Notification",
  },
  {
    id: 14,
    path: "/vouchers",
    icon: <Gift style={{ color: "#e2ac07" }} size={18} />,
    title: "Vouchers",
  },
  {
    id: 15,
    path: "/activities",
    icon: <Activity style={{ color: "#e2ac07" }} size={18} />,
    title: "Activity",
  },
  {
    id: 16,
    path: "/inquiries",
    icon: <Phone style={{ color: "#e2ac07" }} size={18} />,
    title: "Inquiries",
  },
  {
    id: 17,
    path: "/tracking",
    icon: <Monitor style={{ color: "#e2ac07" }} size={18} />,
    title: "Tracking",
  },
  {
    id: 18,
    path: "/chat-info",
    icon: <MessageSquare style={{ color: "#e2ac07" }} size={18} />,
    title: "Chat Info",
  },
  {
    id: 19,
    path: "/settings",
    icon: <Settings style={{ color: "#e2ac07" }} size={18} />,
    title: "Settings",
    subMenu: [
      {
        id: 0,
        path: "/settings",
        icon: <Settings style={{ color: "#e2ac07" }} size={18} />,
        title: "Master Settings",
      },
      {
        id: 1,
        path: "/videoSettings",
        icon: <Settings style={{ color: "#e2ac07" }} size={18} />,
        title: "Video Settings",
      },
    ],
  },
  {
    id: 20,
    path: "/reasons",
    icon: <ThumbsUp style={{ color: "#e2ac07" }} size={18} />,
    title: "Feedback",
  },
  {
    id: 1,
    path: "/ai-assistance",
    icon: <Aperture style={{ color: "#e2ac07" }} size={18} />,
    title: "AI Assisstant",
  },
];

// You can now use the 'data' array in your code.

// ({this.props.unreadInquiriesCount.total})
